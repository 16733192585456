import React from 'react'
import i18n from 'common/util/internationalization'
import { DOCUMENT_STATUS, PREGENERATION_DOCUMENT_STATUS } from '../components/InvitationView/types'
import ToastErrorIcon from 'assets/icons/ToastErrorIcon'
import SuccessIcon from 'assets/icons/SuccessIcon'
import WarningAlertIcon from 'assets/icons/WarningAlertIcon'
import { Link } from './PregeneratedMinutesStyles'
import styled from '@emotion/styled'
import {
    updatePregenerationAIMinutes,
    updateTranscriptAIMinutes
} from 'businesslayer/networkrequests'
import applicationRouter from 'businesslayer/applicationRouter'

const TitleLink = styled('a')<{ status: string }>`
    color: ${({ status }) =>
        status === PREGENERATION_DOCUMENT_STATUS.AUTOMATED ||
        status === PREGENERATION_DOCUMENT_STATUS.PROGRESS
            ? '#05704B'
            : '#AF292E'};
    font-weight: 600;
    padding-top: 16px;
    text-decoration: none;
`

const TitleLinkTranscript = styled('a')<{ status: string }>`
    color: ${({ status }) => {
        if (
            status === PREGENERATION_DOCUMENT_STATUS.AUTOMATED ||
            status === PREGENERATION_DOCUMENT_STATUS.PROGRESS
        ) {
            return '#05704B'
        } else if (
            status === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS ||
            status === PREGENERATION_DOCUMENT_STATUS.NO_SECTION
        ) {
            return '#935206'
        } else {
            return '#AF292E'
        }
    }};
    font-weight: 600;
    padding-top: 16px;
    text-decoration: none;
`

const HelpCentre = styled('a')`
    color: #455d82;
    font-weight: 600;
    text-decoration: none;
`
interface Props {
    currentMinuteItem: any
    removeToast: (id: string) => void
    updateTranscriptGeneration: (status: string) => void
    updateGeneration: (status: string) => void
    toast: any
    documentStatus: string
    forTranscript: boolean
}

class UtilityHandleToast extends React.Component<Props> {
    UtilityHandleToast = () => {
        const { toast, documentStatus, forTranscript } = this.props
        switch (toast?.status) {
            case 'automated':
                return forTranscript
                    ? this.showTranscriptToast(toast, forTranscript)
                    : this.showPregenToast(toast)
            case 'unapproved_error':
                return forTranscript
                    ? this.showTranscriptToast(toast, forTranscript)
                    : this.showPregenToast(toast)
            case 'partial_success':
                return documentStatus === DOCUMENT_STATUS.DRAFT
                    ? forTranscript
                        ? this.showTranscriptToast(toast, forTranscript)
                        : this.showPregenToast(toast)
                    : null
            case 'failed':
                return documentStatus === DOCUMENT_STATUS.DRAFT
                    ? forTranscript
                        ? this.showTranscriptToast(toast, forTranscript)
                        : this.showPregenToast(toast)
                    : null
            case 'complete_mismatch':
                return documentStatus === DOCUMENT_STATUS.DRAFT
                    ? forTranscript
                        ? this.showTranscriptToast(toast, forTranscript)
                        : this.showPregenToast(toast)
                    : null
            case 'no_section':
                return documentStatus === DOCUMENT_STATUS.DRAFT
                    ? forTranscript
                        ? this.showTranscriptToast(toast, forTranscript)
                        : this.showPregenToast(toast)
                    : null
            default:
                return null
        }
    }

    showPregenToast = (toast: any) => {
        const message = i18n.t(toast.statusMessage)
        return toast?.author_id === toast.current_user_id &&
            toast &&
            (toast.view_toast === true ||
                (toast.id === this.props.currentMinuteItem?.id &&
                    (toast.status === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ||
                        toast.status === PREGENERATION_DOCUMENT_STATUS.FAILED ||
                        toast.status === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS) &&
                    toast.view_toast === false)) ? (
            <div className={`custom-toast ${toast.status}`}>
                <div className="custom-toast-header">
                    <span className="custom-toast-icon">
                        {toast.status === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ? (
                            <ToastErrorIcon />
                        ) : toast.status === PREGENERATION_DOCUMENT_STATUS.FAILED ? (
                            <ToastErrorIcon />
                        ) : toast.status === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS ? (
                            <ToastErrorIcon />
                        ) : (
                            <SuccessIcon />
                        )}
                    </span>
                    <div style={{ flexDirection: 'column' }}>
                        <div className={`custom-toast-title ${toast.status}`}>
                            <TitleLink
                                href={`/taker/${toast.id}`}
                                target="blank"
                                status={toast.status}>
                                {toast.MinutesTitle}
                            </TitleLink>
                        </div>
                        <div className="custom-toast-message">{message}</div>
                    </div>
                </div>
                <hr className="custom-toast-border" />
                <div className="custom-toast-buttons">
                    <button
                        className="dismiss"
                        onClick={() => {
                            if (
                                toast.id === this.props.currentMinuteItem?.id &&
                                (toast.status === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ||
                                    toast.status === PREGENERATION_DOCUMENT_STATUS.FAILED ||
                                    toast.status === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS)
                            ) {
                                updatePregenerationAIMinutes(
                                    toast.id,
                                    PREGENERATION_DOCUMENT_STATUS.ACKNOWLEDGED,
                                    false
                                )
                            }
                            this.props.removeToast(toast.id)
                        }}>
                        {i18n.t('TOAST_DISMISS_BUTTON')}
                    </button>
                    {toast.id !== this.props.currentMinuteItem?.id && (
                        <button
                            className="action"
                            onClick={() => {
                                if (
                                    toast.id === this.props.currentMinuteItem?.id &&
                                    (toast.status ===
                                        PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ||
                                        toast.status === PREGENERATION_DOCUMENT_STATUS.FAILED ||
                                        toast.status ===
                                            PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS)
                                ) {
                                    this.props.updateGeneration(
                                        PREGENERATION_DOCUMENT_STATUS.ACKNOWLEDGED
                                    )
                                }
                                applicationRouter.navigateTo(`/taker/${toast.id}`)
                            }}>
                            <Link
                                style={{ textDecoration: 'none' }}
                                href={`/taker/${toast.id}`}
                                target="blank">
                                {i18n.t('VIEW')}
                            </Link>
                        </button>
                    )}
                </div>
            </div>
        ) : null
    }

    showTranscriptToast = (toast: any, forTranscript) => {
        const message = i18n.t(toast.statusMessage)
        return toast?.author_id === toast.current_user_id &&
            toast &&
            ((toast.view_toast === true &&
                !(forTranscript && toast.status === PREGENERATION_DOCUMENT_STATUS.AUTOMATED)) ||
                (toast.id === this.props.currentMinuteItem?.id &&
                    (toast.status === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ||
                        toast.status === PREGENERATION_DOCUMENT_STATUS.FAILED ||
                        toast.status === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS ||
                        toast.status === PREGENERATION_DOCUMENT_STATUS.COMPLETE_MISMATCH ||
                        toast.status === PREGENERATION_DOCUMENT_STATUS.NO_SECTION) &&
                    toast.view_toast === false)) ? (
            <div className={`custom-toast-ttm ${toast.status}`}>
                <div className="custom-toast-header">
                    <span className="custom-toast-icon">
                        {toast.status === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ? (
                            <ToastErrorIcon />
                        ) : toast.status === PREGENERATION_DOCUMENT_STATUS.FAILED ? (
                            <ToastErrorIcon />
                        ) : toast.status === PREGENERATION_DOCUMENT_STATUS.COMPLETE_MISMATCH ? (
                            <ToastErrorIcon />
                        ) : toast.status === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS ? (
                            <WarningAlertIcon />
                        ) : toast.status === PREGENERATION_DOCUMENT_STATUS.NO_SECTION ? (
                            <WarningAlertIcon />
                        ) : (
                            <SuccessIcon />
                        )}
                    </span>
                    <div style={{ flexDirection: 'column' }}>
                        <div className={`custom-toast-title-ttm ${toast.status}`}>
                            <TitleLinkTranscript
                                href={`/taker/${toast.id}`}
                                target="blank"
                                status={toast.status}>
                                {toast.MinutesTitle}
                            </TitleLinkTranscript>
                        </div>
                        <div className="custom-toast-message">{message}</div>
                        <div>
                            {(toast.status === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS ||
                                toast.status ===
                                    PREGENERATION_DOCUMENT_STATUS.COMPLETE_MISMATCH) && (
                                <HelpCentre
                                    href={`https://help.highbond.com/helpdocs/boards_on_prem/en-us/Default.htm#cshid=boards-minutes-transcript-to-minutes`}
                                    target="blank">
                                    {i18n.t('HELP_CENTRE')}
                                </HelpCentre>
                            )}
                        </div>
                    </div>
                </div>
                <hr className="custom-toast-border" />
                <div className="custom-toast-buttons">
                    <button
                        className="dismiss"
                        onClick={() => {
                            if (
                                toast.id === this.props.currentMinuteItem?.id &&
                                (toast.status === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ||
                                    toast.status === PREGENERATION_DOCUMENT_STATUS.FAILED ||
                                    toast.status ===
                                        PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS ||
                                    toast.status ===
                                        PREGENERATION_DOCUMENT_STATUS.COMPLETE_MISMATCH)
                            ) {
                                updateTranscriptAIMinutes(
                                    toast.id,
                                    PREGENERATION_DOCUMENT_STATUS.ACKNOWLEDGED,
                                    false
                                )
                            }
                            this.props.removeToast(toast.id)
                        }}>
                        {i18n.t('TOAST_DISMISS_BUTTON')}
                    </button>
                    {toast.id !== this.props.currentMinuteItem?.id && (
                        <button
                            className="action"
                            onClick={() => {
                                if (
                                    toast.id === this.props.currentMinuteItem?.id &&
                                    (toast.status ===
                                        PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ||
                                        toast.status === PREGENERATION_DOCUMENT_STATUS.FAILED ||
                                        toast.status ===
                                            PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS)
                                ) {
                                    this.props.updateTranscriptGeneration(
                                        PREGENERATION_DOCUMENT_STATUS.ACKNOWLEDGED
                                    )

                                    applicationRouter.navigateTo(`/taker/${toast.id}`)
                                }
                            }}>
                            <Link
                                style={{ textDecoration: 'none' }}
                                href={`/taker/${toast.id}`}
                                target="blank">
                                {i18n.t('VIEW')}
                            </Link>
                        </button>
                    )}
                </div>
            </div>
        ) : (
            forTranscript &&
                toast.status === PREGENERATION_DOCUMENT_STATUS.AUTOMATED &&
                toast.view_toast === true && (
                    <div className={`custom-toast ${toast.status}`}>
                        <div className="custom-toast-header">
                            <div style={{ display: 'flex' }}>
                                <span className={`custom-toast-icon ${toast.status}`}>
                                    <SuccessIcon />
                                </span>
                                <div className="custom-toast-message">{message}</div>
                            </div>
                            <div className="custom-toast-buttons">
                                <button
                                    className="dismiss"
                                    onClick={() => {
                                        this.props.removeToast(toast.id)
                                    }}>
                                    {i18n.t('TOAST_DISMISS_BUTTON')}
                                </button>
                            </div>
                        </div>
                    </div>
                )
        )
    }

    render() {
        return this.UtilityHandleToast()
    }
}

export default UtilityHandleToast
