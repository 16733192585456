import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { actions } from 'reducers/applicationReducer'
import { TakerControlState, DataState } from 'reducers/minuteTakerReducer'

/** Change Event Handler */
export type InputChange = (e: ChangeEvent<HTMLInputElement>) => void

/** Single reviewer shape */
export interface Reviewer {
    id: string
    attributes: {
        display_name: string
        email: string
        displayName?: string
    }
}

/** Reviewer resonse shape */
export interface ReviewersResponse {
    data: Reviewer[]
}

export interface GeneralReviewerProps {
    minuteTakerState: TakerControlState
    updateReviewers: typeof actions.updateReviewers
    dataState: DataState
    chipOptions: PersonChip[]
    errorObject: boolean
    setErrorObject: Dispatch<SetStateAction<boolean>>
    adminList: number[]
    showHasHtmlMessage?: { body: boolean; subject: boolean }
    setShowHasHtmlMessage?: Dispatch<SetStateAction<{ body: boolean; subject: boolean }>>
}
export interface GeneralReviewTableProps
    extends Omit<Omit<GeneralReviewerProps, 'errorObject'>, 'chipOptions'> {
    restrictedUsers: PersonChip[]
}

export enum INVITEE_CONSTANTS {
    ATTENDEES = 'attendees',
    REMOVE_VALUE = 'remove-value',
    SAME_DUE_DATE = 'same_due_date',
    DIFFERENT_DUE_DATE = 'different_due_date',
    NO_DUE_DATE = 'no_due_date',
    EMPTY = '',
    DATE_FORMAT = 'YYYY-MM-DD',
    DATE_FORMAT_TIME = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
    DATE_FORMAT_EMAIL = 'ddd MMM DD YYYY',
    SUBJECT = 'subject',
    BODY = 'body',
    POP_VALUE = 'pop-value'
}
export enum DATE_STEPS {
    NO_DUE_DATE = 'no_due_date',
    DIFFERENT_DUE_DATE = 'different_due_date',
    SAME_DUE_DATE = 'same_due_date',
    INVALID_DATE = 'Invalid date',
    DUE_DATE_FIELDS = 'due_date_fields'
}
export enum CHAT_BOT {
    QUESTION = 'question',
    SYSTEM = 'system',
    USER = 'user',
    MINUTES = 'Minutes'
}
export interface InvitationViewProps {
    workroomName: string
    minutesId: string
    minutesName: string
    isVisible: boolean
    dateFormat: string
    onClose: () => void
    onSubmit: (networkResponse: any) => void
    platform: string
    minuteTakerState: TakerControlState
    updateReviewers: typeof actions.updateReviewers
    dataState: DataState
    reviewersList: PersonChip[]
    setLoaded: React.Dispatch<React.SetStateAction<boolean>>
}
export interface UseInvitationViewProps extends InvitationViewProps {}

export type SelectChangeAction = {
    action: INVITEE_CONSTANTS
    removedValue: { review_id: number; id: number }
    option: {
        review_id: number
        id: number
    }
}
export interface EmailNotificationProps extends Omit<GeneralReviewerProps, 'adminList'> {}

export interface AiChatBotProps {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    chatMessages: ChatObjects[]
    actions: {
        setChatMessages: (data: ChatObjects) => void
    }
    isOpen: boolean
    identityToken: ChatTokenResponse
}
export interface UseChatBotAIConnectorProps extends AiChatBotProps {}

export interface AiChatTokenProps {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    identityToken: ChatTokenResponse
    actions: {
        setIdentityToken: (data: ChatTokenResponse) => void
    }
}
export interface UseChatTokenResponse extends AiChatTokenProps {}

export enum EDITOR_TABS {
    EDITOR = 'minutes-editor',
    ATTENDEES = 'minutes-attendees',
    ACTIONS = 'minutes-actions'
}

export enum DOCUMENT_STATUS {
    DRAFT = 'draft',
    IN_REVIEW = 'in_review',
    FINAL_DRAFT = 'final_draft',
    APPROVED = 'approved'
}

export enum MINUTES_EDIT_OPTIONS {
    EDIT = 'EDIT',
    ACTIONS = 'ACTIONS',
    DUPLICATE = 'DUPLICATE',
    EXPORT_MINUTES = 'EXPORT_MINUTES',
    EXPORT_MINUTES_WITH_ACTIONS = 'EXPORT_MINUTES_WITH_ACTIONS',
    EXPORT_ACTIONS = 'EXPORT_ACTIONS',
    EXPORT_CONSOLIDATED_ACTIONS = 'EXPORT_CONSOLIDATED_ACTIONS',
    DELETE = 'DELETE'
}

export enum PREGENERATION_DOCUMENT_STATUS {
    PROGRESS = 'progress',
    AUTOMATED = 'automated',
    FAILED = 'failed',
    PARTIAL_SUCCESS = 'partial_success',
    UNAPPROVED_ERROR = 'unapproved_error',
    DISMISSED = 'dismissed',
    ACKNOWLEDGED = 'acknowledged',
    CANCELED = 'canceled',
    MANUAL = 'manual',
    COMPLETE_MISMATCH = 'complete_mismatch',
    NO_SECTION = 'no_section'
}

export enum TRANSCRIPT_UPLOAD_STATUS {
    PROGRESS = 'progress',
    DEFAULT = 'default',
    FAILED = 'failed',
    UNSUPPORTED_FORMAT = 'unsupportedFormat',
    SUCCESS = 'success',
    FILE_NAME_LENGTH = 'fileLength',
}
