import React from 'react'
import { Dialog, IconButton, Divider, CircularProgress, Button, Box } from '@material-ui/core'
import { ClassNames } from '@emotion/react'
import i18n from 'common/util/internationalization'
import { AIGenerationIcon } from 'assets/icons/AiGenerationIcon'
import { AtlasIcon } from 'web-components/atlas-icon'
import ErrorIcon from 'assets/icons/ErrorIcon'
import UploadIcon from 'assets/icons/UploadIcon'
import {
    ColorEastBay,
    AtlasGreyLight,
    ColorBaseMulberryBlue,
    ColorBaseWhite
} from 'assets/styles/variables'
import {
    UploadContainer,
    UploadInput,
    UploadText,
    UploadLink,
    UploadInfo,
    UploadSection,
    UploadIconStyle,
    UploadFileDetails,
    HighlightedText,
    StyledDeleteSectionIcon,
    StyledSuccessIcon,
    UploadFileSize,
    UploadFileError
} from './UploadStyles'

import {
    HeaderText,
    ProgressMessage,
    aiDialogContainer,
    aiDialogHeader,
    aiGenerateFooter,
    customButton
} from '../sectionlist/PregeneratedMinutesStyles'
import { makeStyles } from '@material-ui/core/styles'
import { TRANSCRIPT_UPLOAD_STATUS } from '../components/InvitationView/types'
import { AtlasTooltip } from 'components/atlas-components/atlas-tooltip/Tooltip'

const useStyles = makeStyles({
    myIconButton: {
        marginLeft: 'auto',
        '&:focus': {
            border: `2px solid ${ColorBaseMulberryBlue}`,
            padding: '5px',
            borderRadius: '40px'
        }
    },
    myButton: {
        '&:focus, &:active': {
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    }
})

interface FileItem {
    name: string
    size: number
    file: File | null
}

interface TtmUploadDialogProps {
    updateFileDetailsAndStatus: (file: FileItem, status: string) => void
    handleOpenUploadModal: (...args: any[]) => void
    handleSelectTranscriptFile: (...args: any[]) => void
    handleOpenTtmModal: (...args: any[]) => void
    uploadModal: boolean
    uploadStatus: string
    items: FileItem
    deleteFileForTranscript: (...args: any[]) => void
    transcriptId: string
    fileUploadError: string
}

const TtmUploadDialog: React.FC<TtmUploadDialogProps> = (props) => {
    const classes = useStyles(props)
    return (
        <ClassNames>
            {({ css }) => (
                <Dialog
                    open={props?.uploadModal}
                    className={css`
                        .MuiPaper-root.MuiDialog-paper {
                            max-width: 1000px;
                            width: 1000px;
                            max-height: 100%;
                            border-radius: 8px;
                        }
                    `}
                    onClose={() => props.handleOpenUploadModal(props?.uploadModal)}
                    disableBackdropClick
                    disableEscapeKeyDown>
                    <div className={aiDialogContainer}>
                        <div className={aiDialogHeader}>
                            <AIGenerationIcon />
                            <HeaderText>{i18n.t('AI_TTM_MINUTES')}</HeaderText>
                            <IconButton
                                tabIndex={0}
                                disableFocusRipple
                                disableRipple
                                onClick={() => {
                                    props.handleOpenUploadModal(props?.uploadModal)
                                    if (props.transcriptId) {
                                        props.deleteFileForTranscript(props.transcriptId)
                                    }
                                    props.updateFileDetailsAndStatus(
                                        { name: '', size: 0, file: null },
                                        TRANSCRIPT_UPLOAD_STATUS.DEFAULT
                                    )
                                }}
                                className={classes.myIconButton}>
                                <AtlasIcon
                                    name="close"
                                    size={24}
                                    data-testid="AtlasModal_CloseIcon"
                                />
                            </IconButton>
                        </div>
                        <Divider />
                        <ProgressMessage>{i18n.t('AI_TTM_DESCRIPTION')}</ProgressMessage>
                        <ProgressMessage>
                            {props.uploadStatus === TRANSCRIPT_UPLOAD_STATUS.DEFAULT && (
                                <>
                                    <UploadContainer
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => {
                                            e.preventDefault()
                                            if (
                                                e.dataTransfer.files &&
                                                e.dataTransfer.files.length > 0
                                            ) {
                                                const file = e.dataTransfer.files[0]
                                                const validFileTypes = ['.vtt', '.txt', '.docx']
                                                const fileType = file.name.split('.').pop()
                                                if (validFileTypes.includes(`.${fileType}`)) {
                                                    props.handleSelectTranscriptFile({
                                                        target: { files: e.dataTransfer.files }
                                                    } as React.ChangeEvent<HTMLInputElement>)
                                                } else {
                                                    props.updateFileDetailsAndStatus(
                                                        {
                                                            name: file.name,
                                                            size: file.size,
                                                            file: file
                                                        },
                                                        TRANSCRIPT_UPLOAD_STATUS.UNSUPPORTED_FORMAT
                                                    )
                                                }
                                            }
                                        }}>
                                        <UploadInput
                                            type="file"
                                            accept=".vtt, .txt, .docx"
                                            onChange={(event) =>
                                                props.handleSelectTranscriptFile(event)
                                            }
                                            id="fileUpload"
                                        />
                                        <UploadText>
                                            <UploadIconStyle>
                                                <UploadIcon />{' '}
                                            </UploadIconStyle>
                                            <label
                                                htmlFor="fileUpload"
                                                style={{
                                                    cursor: 'pointer',
                                                    display: 'inline-block'
                                                }}
                                                tabIndex={0}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                        document
                                                            .getElementById('fileUpload')
                                                            ?.click()
                                                    }
                                                }}>
                                                <UploadLink as="span">
                                                    {i18n.t('AI_TTM_UPLOAD_DOC')}
                                                </UploadLink>
                                            </label>
                                            | <span>{i18n.t('AI_TTM_DRAG_DROP')}</span>
                                        </UploadText>
                                    </UploadContainer>
                                    <UploadInfo>
                                        {i18n.t('AI_TTM_FORMAT_SUPPORTED')}
                                        <br />
                                        {i18n.t('AI_TTM_MAX_FILE_SIZE')}
                                        <br />
                                        {/* {i18n.t('AI_TTM_MAX_NUM_WORDS')} */}
                                    </UploadInfo>
                                </>
                            )}
                            {(props.uploadStatus === TRANSCRIPT_UPLOAD_STATUS.PROGRESS ||
                                props.uploadStatus === TRANSCRIPT_UPLOAD_STATUS.SUCCESS ||
                                props.uploadStatus === TRANSCRIPT_UPLOAD_STATUS.FAILED ||
                                props.uploadStatus ===
                                    TRANSCRIPT_UPLOAD_STATUS.UNSUPPORTED_FORMAT ||
                                props.uploadStatus ===
                                    TRANSCRIPT_UPLOAD_STATUS.FILE_NAME_LENGTH) && (
                                <UploadSection>
                                    {props.uploadStatus === TRANSCRIPT_UPLOAD_STATUS.PROGRESS && (
                                        <CircularProgress size={34} thickness={4} color="primary" />
                                    )}
                                    {props.uploadStatus === TRANSCRIPT_UPLOAD_STATUS.SUCCESS && (
                                        <StyledSuccessIcon />
                                    )}
                                    {(props.uploadStatus === TRANSCRIPT_UPLOAD_STATUS.FAILED ||
                                        props.uploadStatus ===
                                            TRANSCRIPT_UPLOAD_STATUS.UNSUPPORTED_FORMAT ||
                                        props.uploadStatus ===
                                            TRANSCRIPT_UPLOAD_STATUS.FILE_NAME_LENGTH) && (
                                        <ErrorIcon />
                                    )}

                                    <UploadFileDetails>
                                        <HighlightedText>{props.items?.name}</HighlightedText>
                                        {props.uploadStatus ===
                                            TRANSCRIPT_UPLOAD_STATUS.SUCCESS && (
                                            <UploadFileSize>
                                                {props.items?.size < 1048576
                                                    ? `${(props.items?.size / 1024).toFixed(2)} KB`
                                                    : `${(props.items?.size / 1048576).toFixed(
                                                          2
                                                      )} MB`}
                                            </UploadFileSize>
                                        )}
                                        {(props.uploadStatus === TRANSCRIPT_UPLOAD_STATUS.FAILED ||
                                            props.uploadStatus ===
                                                TRANSCRIPT_UPLOAD_STATUS.FILE_NAME_LENGTH) && (
                                                <UploadFileError>
                                                    {props.fileUploadError
                                                        ? props.fileUploadError
                                                        : props.uploadStatus ===
                                                          TRANSCRIPT_UPLOAD_STATUS.FILE_NAME_LENGTH
                                                        ? i18n.t('AI_TTM_FILE_NAME_LENGTH_ERROR')
                                                        : i18n.t('AI_TTM_UPLOAD_ERROR')}
                                                </UploadFileError>
                                            )}
                                        {props.uploadStatus ===
                                            TRANSCRIPT_UPLOAD_STATUS.UNSUPPORTED_FORMAT && (
                                            <UploadFileError>
                                                {i18n.t('AI_TTM_UNSUPPORTED_FILE')}
                                            </UploadFileError>
                                        )}
                                    </UploadFileDetails>
                                    {(props.uploadStatus === TRANSCRIPT_UPLOAD_STATUS.SUCCESS ||
                                        props.uploadStatus === TRANSCRIPT_UPLOAD_STATUS.FAILED ||
                                        props.uploadStatus ===
                                            TRANSCRIPT_UPLOAD_STATUS.UNSUPPORTED_FORMAT ||
                                        props.uploadStatus ===
                                            TRANSCRIPT_UPLOAD_STATUS.FILE_NAME_LENGTH) && (
                                        <AtlasTooltip
                                            title={i18n.t('DELETE_FILE') || 'Delete File'}
                                            placement="top-start">
                                            <IconButton
                                                tabIndex={0}
                                                disableFocusRipple
                                                disableRipple
                                                className={classes.myIconButton}
                                                onClick={() => {
                                                    if (props.transcriptId) {
                                                        props.deleteFileForTranscript(
                                                            props.transcriptId
                                                        )
                                                    }
                                                    props.updateFileDetailsAndStatus(
                                                        { name: '', size: 0, file: null },
                                                        TRANSCRIPT_UPLOAD_STATUS.DEFAULT
                                                    )
                                                }}>
                                                <StyledDeleteSectionIcon title={<></>} />
                                            </IconButton>
                                        </AtlasTooltip>
                                    )}
                                </UploadSection>
                            )}
                        </ProgressMessage>
                        <Divider />
                        <Box className={aiGenerateFooter}>
                            <Button
                                className={`${classes.myButton} ${customButton}`}
                                disableRipple
                                disableFocusRipple
                                style={{
                                    backgroundColor:
                                        props.uploadStatus !== TRANSCRIPT_UPLOAD_STATUS.SUCCESS
                                            ? AtlasGreyLight
                                            : ColorEastBay,
                                    color:
                                        props.uploadStatus !== TRANSCRIPT_UPLOAD_STATUS.SUCCESS
                                            ? '#B3B3B3'
                                            : ColorBaseWhite,
                                    opacity: 1,
                                    pointerEvents:
                                        props.uploadStatus !== TRANSCRIPT_UPLOAD_STATUS.SUCCESS
                                            ? 'none'
                                            : 'auto'
                                }}
                                onClick={() => {
                                    props.handleOpenTtmModal()
                                }}
                                data-analytics="MM-AI-Transcript-Cancel"
                                disabled={props.uploadStatus !== TRANSCRIPT_UPLOAD_STATUS.SUCCESS}>
                                {i18n.t('AI_TTM_START_GENERATING')}
                            </Button>
                        </Box>
                    </div>
                </Dialog>
            )}
        </ClassNames>
    )
}

export default TtmUploadDialog
