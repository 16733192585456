import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { AtlasActionPrimary, ColorBaseSkyBlue } from 'assets/styles/variables'

export const ToastContainer = styled('div')`
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 10px;
`
export const HeaderText = styled('span')`
    font-size: 22px;
    font-weight: 600;
    padding-left: 5px;
`
export const ProgressMessage = styled('p')`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    padding: 20px;
    line-height: 20px;
`
export const TranscriptProgressMessage = styled('p')`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    padding: 32px;
    line-height: 20px;
`
export const Link = styled('a')`
    color: #455d82;
    font-weight: 700;
`

export const ToolbarCss = css`
    display: flex;
    gap: 16px;
    align-items: center;
`
export const ToolbarBoxCss = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: 1px solid #e6e6e6;
`
export const listSubheader = css`
    z-index: 96;
    padding-left: 0;
    padding-right: 0;
`
export const aiDialogContainer = css`
    padding-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 3px solid transparent;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(169.21deg, #d3222a 0.16%, #b646f6 46.64%, #4d1dd5 98.77%) border-box;
`

export const aiDialogHeader = css`
    display: flex;
    align-items: center;
    height: 58px;
    padding: 16px;
`
export const aiGenerateFooter = css`
    display: flex;
    gap: 10px;
    float: right;
    padding-right: 20px;
    padding-top: 10px;
    place-content: end;
`
export const customButton = css`
    display: flex;
    align-items: center;
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 12px;
`

export const aiGeneratedNotification = css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    min-width: 700px;
    max-width: 1090px;
    margin: 24px auto;
`
export const aiGeneratedNotificationBody = css`
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    justify-content: space-between;
    background: linear-gradient(
            128deg,
            var(--Color-Brand-Primary-Red-2, rgba(211, 34, 42, 0.1)) 17.49%,
            var(--Color-Data-Visualization-Qualitative-Purple-01, rgba(194, 71, 250, 0.1)) 70.3%,
            var(--Color-Data-Visualization-Qualitative-Purple-04, rgba(77, 29, 213, 0.1)) 100%
        ),
        rgba(255, 255, 255, 0.2);
`
export const aiGeneratedNotificationContent = css`
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
`
export const btnDismiss = css`
    color: ${AtlasActionPrimary};
    height: 40px;
    span {
        font-family: 'Source Sans Pro';
        font-weight: 600;
        font-size: 16px;
        text-transform: capitalize;
    }
    :focus {
        box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px ${ColorBaseSkyBlue};
        border-radius: 2px;
    }
`
