import { setLocalStorageItem } from 'businesslayer/minutesLocalStore'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import {
    updatePregenerationAIMinutes,
    updateTranscriptAIMinutes
} from 'businesslayer/networkrequests'
import { getPreGenerationStatus } from 'businesslayer/networkrequests/preGenerationStatusService'
import i18n from 'common/util/internationalization'
import { useCommitteesState } from 'components/contexts/committee-context'
import { PREGENERATION_DOCUMENT_STATUS } from 'components/minutetaker/components/InvitationView/types'
import React from 'react'

interface ToastProp {
    id: number
    preGenPollingStatus: string
    minuteInitiateUser: string
    loggedInUser: string
    statusMessage: string
    view_toast: boolean
    MinutesTitle: string
    forTranscript: boolean
}

export const useMinutesPolling = () => {
    const [toasts, setToasts] = React.useState<ToastProp[]>([])
    const [minutesDocuments, setMinutesDocuments] = React.useState([])
    const [transcriptDocuments, setTranscriptDocuments] = React.useState([])
    const [isPolling, setIsPolling] = React.useState(true)
    const { adminCommittees } = useCommitteesState()

    const fetchMinutesDocuments = async () => {
        try {
            const committeeIdsArray = adminCommittees
                .map((item) => String(item.id))
                .filter((value, index, self) => self.indexOf(value) === index)
            setLocalStorageItem('committeeIds', JSON.stringify(committeeIdsArray))
            const response =
                isPolling === true ? await getPreGenerationStatus(committeeIdsArray) : null
            const pregenDocumentsData = response?.data
            const transcriptData = response?.transcript
            setMinutesDocuments(pregenDocumentsData)
            setTranscriptDocuments(transcriptData)

            const hasPregenProgress = pregenDocumentsData.some(
                (item: any) =>
                    item.attributes.pregenerated_minutes === PREGENERATION_DOCUMENT_STATUS.PROGRESS
            )
            const hasTranscriptProgress = transcriptData.some(
                (item: any) =>
                    item.attributes.transcript_status === PREGENERATION_DOCUMENT_STATUS.PROGRESS
            )
            if (!(hasPregenProgress || hasTranscriptProgress)) {
                setIsPolling(false)
            }

            const newToasts = pregenDocumentsData
                .filter(
                    ({ attributes }) =>
                        attributes.pregenerated_minutes !== PREGENERATION_DOCUMENT_STATUS.PROGRESS
                )
                .map(({ attributes }) => ({
                    id: `${attributes.document_id}`,
                    MinutesTitle:
                        attributes.pregenerated_minutes ===
                        PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR
                            ? i18n.t('AI_GENERATE_FAIL_TITLE')
                            : attributes.pregenerated_minutes ===
                              PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                            ? i18n.t('MEETING_MINUTES_READY')
                            : attributes.pregenerated_minutes ===
                              PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS
                            ? i18n.t('AI_GENERATE_PARTIAL_FAIL_TITLE')
                            : i18n.t('AI_GENERATE_FAIL_TITLE'),
                    minuteInitiateUser: `${attributes.author_id}`,
                    loggedInUser: `${transientStorageManager?.currentUser?.id}`,
                    preGenPollingStatus: `${attributes.pregenerated_minutes}`,
                    view_toast: attributes.view_toast,
                    statusMessage:
                        attributes.pregenerated_minutes ===
                        PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR
                            ? i18n.t('UNAPPROVED_STATUS', {
                                  minutesTitle: attributes.title
                              })
                            : attributes.pregenerated_minutes ===
                              PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                            ? i18n.t('AI_GENERATE_SUCCESS_DESCRIPTION', {
                                  minutesTitle: attributes.title
                              })
                            : attributes.pregenerated_minutes ===
                              PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS
                            ? i18n.t('AI_GENERATE_PARTIAL_FAIL_SUBTITLE', {
                                  minutesTitle: attributes.title
                              })
                            : i18n.t('AI_GENERATE_FAIL_SUBTITLE', {
                                  minutesTitle: attributes.title
                              }),
                    forTranscript: false
                }))

            const newTranscriptToasts = transcriptData
                .filter(
                    ({ attributes }) =>
                        attributes.transcript_status !== PREGENERATION_DOCUMENT_STATUS.PROGRESS
                )
                .map(({ attributes }) => ({
                    id: `${attributes.document_id}`,
                    MinutesTitle:
                        attributes.transcript_status ===
                        PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR
                            ? i18n.t('AI_GENERATE_FAIL_TITLE')
                            : attributes.transcript_status ===
                              PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                            ? i18n.t('MEETING_MINUTES_READY')
                            : attributes.transcript_status ===
                              PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS
                            ? i18n.t('AI_TTM_GENERATE_PARTIAL_FAIL_TITLE')
                            : i18n.t('FAILED_TO_GENERATE'),
                    minuteInitiateUser: `${attributes.author_id}`,
                    loggedInUser: `${transientStorageManager?.currentUser?.id}`,
                    preGenPollingStatus: `${attributes.transcript_status}`,
                    view_toast: attributes.view_toast,
                    statusMessage:
                        attributes.transcript_status ===
                        PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR
                            ? i18n.t('UNAPPROVED_STATUS', {
                                  minutesTitle: attributes.title
                              })
                            : attributes.transcript_status ===
                              PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                            ? i18n.t('AI_TTM_GENERATE_SUCCESS_SUBTITLE_DESCRIPTION', {
                                  minutesTitle: attributes.title
                              })
                            : attributes.transcript_status ===
                              PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS
                            ? i18n.t('AI_TTM_GENERATE_PARTIAL_FAIL_SUBTITLE_DESCRIPTION', {
                                  minutesTitle: attributes.title
                              })
                            : attributes.transcript_status ===
                              PREGENERATION_DOCUMENT_STATUS.COMPLETE_MISMATCH
                            ? i18n.t('AI_TTM_GENERATE_FAIL_SUBTITLE_DESCRIPTION', {
                                  minutesTitle: attributes.title
                              })
                            : i18n.t('AI_TTM_GENERATE_FAIL', {
                                  minutesTitle: attributes.title
                              }),
                    forTranscript: true
                }))

            setToasts((prevToasts) => [...prevToasts, ...newToasts, ...newTranscriptToasts])
            ;[...newToasts, ...newTranscriptToasts].forEach((toast) => {
                setTimeout(() => {
                    setToasts((prevToasts) => prevToasts.filter((t) => t.id !== toast.id))
                    if (toast.view_toast === true) {
                        if (toast.forTranscript) {
                            updateTranscriptAIMinutes(toast.id, toast.status, false)
                        } else {
                            updatePregenerationAIMinutes(toast.id, toast.status, false)
                        }
                    }
                }, 15000)
            })
        } catch (error) {
            console.error('Error fetching minutes pregenDocumentsData:', error)
        }
    }

    return {
        isPolling,
        toasts,
        setToasts,
        minutesDocuments,
        fetchMinutesDocuments,
        transcriptDocuments
    }
}
